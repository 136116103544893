<script lang="ts" setup>
import type { ProductOption, SelectedOption } from '../type/common';
import type { PreVariantSelected, Product } from './modal/MultiProductModal.vue';
import MultiProductModal from './modal/MultiProductModal.vue';
import { computed, ref } from 'vue';
import { GReorder } from '@gem/uikit';
import { isDefined } from '../utils/common';
import ProductItemPicked from './product-picked/ProductItemPicked.vue';

type Props = {
  id?: string;
  value?: string[];
  productDisplay?: Product[];
  isLoading?: boolean;
  options?: ProductOption[];
  variants?: SelectedOption[];
  productList?: Product[];
  max?: number;
  connectStatus?: boolean;
  syncPercent?: number;
  pageType?: string;
  warningMessage?: string;
  inputSearchComponent?: Object;
  tag?: string;
  maxAllowSelected?: number;
  variantSelected?: PreVariantSelected;
  isPostPurchase?: boolean;
};

type Item = {
  id?: string;
  title?: string;
  images?: string;
  handle?: string;
};

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'controlOnChange', controlId?: string | number, value?: any): void;
  (e: 'controlChange', controlId?: string | number, value?: Product[]): void;
  (e: 'controlFocus', controlId?: string | number, value?: any): void;
  (e: 'controlBlur', controlId?: string | number, value?: any): void;
  (e: 'controlOnSearch', controlId?: string | number, value?: any): void;
  (e: 'changeVariants', value?: any): void;
  (e: 'changeVariantSelected', controlId?: string, variants?: PreVariantSelected): void;
  (e: 'controlRefresh'): void;
  (e: 'connect-shopify'): void;
  (e: 'showMore', action?: 'fetch'): void;
  (e: 'openSaleFunnel'): void;
}>();

const productListVisibility = ref<boolean>(false);
const FIRST_VIEW_ITEM = 4;
const end = ref(FIRST_VIEW_ITEM);
const searchValue = ref('');

const deleteItems = ref(0);

const closeModal = () => {
  productListVisibility.value = false;
};

const openModal = () => {
  productListVisibility.value = true;
  emit('showMore'); // trigger  for wrap know product list modal is open
};

const setValueSelected = (productList?: Product[]) => {
  emit('controlChange', props.id, productList || []);
};

const setPreVariantSelected = (value?: PreVariantSelected) => {
  emit('changeVariantSelected', props.id, value);
};

const onSearch = (value?: string) => {
  emit('controlOnSearch', props.id, value);
  searchValue.value = value ?? '';
};

const cloneList = computed(() => {
  return props.value?.map((id) => props.productDisplay?.find((el) => el.id === id)).filter(isDefined);
});

const deleteSingleItem = (id: string) => {
  const newProductList = cloneList.value?.filter((v) => v?.id !== id);
  emit('controlChange', props.id, newProductList);
  const newVariants = { ...props.variantSelected };
  delete newVariants[id];
  emit('changeVariantSelected', props.id, newVariants);
  deleteItems.value++;
};

const next = () => {
  if (cloneList.value?.length ?? 0 <= end.value) {
    end.value = cloneList.value?.length ?? 0;
  }
};

const prev = () => {
  if ((cloneList.value?.length ?? 0 >= end.value) && end.value > FIRST_VIEW_ITEM) {
    end.value = FIRST_VIEW_ITEM;
  }
};

const list = computed(() => {
  if (props.value && props.value.length == 0) {
    return [];
  }
  if (cloneList.value?.length === 0) {
    return [];
  } else if (cloneList.value?.length ?? 0 > end.value) {
    return [...(cloneList.value?.slice(0, end.value) ?? [])].map((v) => ({
      id: v?.id,
      title: v?.title,
      image: v?.image,
      handle: v?.handle,
      options: v?.options,
      variants: v?.variants,
      status: v?.status,
    }));
  } else return [];
});

function handleReorder(items: Item[], _from?: number, _to?: number) {
  if (items.length) {
    emit(
      'controlChange',
      props.id,
      cloneList.value?.map((v, index) => {
        if (index < end.value) {
          return items[index] as Product;
        }
        return v;
      }),
    );
  }
}

const openSaleFunnel = () => {
  emit('openSaleFunnel');
};

const isSalePage = computed(() => {
  return props.pageType === 'GP_FUNNEL_PAGE' && props.tag === 'Product';
});

const listSortable = computed(() => {
  if (!props.isPostPurchase) return props.productList;
  const idsSelected = list.value.map((el) => el.id);
  return [
    ...list.value.filter((el) => el.title?.toLowerCase().includes(searchValue.value.toLowerCase())),
    ...(props?.productList || []).filter((el) => !idsSelected.includes(el.id)),
  ];
});
</script>
<template>
  <div class="gemx-control">
    <div>
      <div class="flex flex-col gap-8">
        <div v-if="list && list.length" class="bg-dark-500 rounded-medium mx-[-7px] flex flex-col gap-4 p-8">
          <GReorder :items="list" @re-order="handleReorder">
            <template #default="{ item }">
              <ProductItemPicked
                :item="item"
                :list="list"
                :product-list="productList"
                :variant-selected="variantSelected"
                :is-post-purchase="isPostPurchase"
                @delete-item="deleteSingleItem"
                @open-modal="openModal" />
            </template>
          </GReorder>
          <div
            v-if="
              (end < (cloneList?.length ?? 0) || end > FIRST_VIEW_ITEM) && (cloneList?.length ?? 0) > FIRST_VIEW_ITEM
            "
            class="grid grid-cols-2 gap-8">
            <GButtonV2
              type="secondary"
              icon-before="minus-gemx"
              icon-view-box="0 0 16 16"
              padding-x="8"
              size="medium"
              button-width="full"
              :disable="end <= FIRST_VIEW_ITEM"
              @click="prev()">
              Show less
            </GButtonV2>
            <GButtonV2
              type="secondary"
              icon-before="plus-gemui"
              icon-view-box="0 0 16 16"
              padding-x="8"
              button-width="full"
              size="medium"
              :disable="end >= (cloneList?.length ?? 0)"
              @click="next()">
              Show more
            </GButtonV2>
          </div>
        </div>
        <div v-else class="bg-dark-400 rounded-medium flex h-[48px] items-center gap-8 p-8">
          <div class="rounded-medium aspect-square h-32 w-32 shrink-0 overflow-hidden object-cover">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.10051 6.10051C11.5678 0.633164 20.4322 0.633164 25.8995 6.10051C31.3668 11.5678 31.3668 20.4322 25.8995 25.8995C20.4322 31.3668 11.5678 31.3668 6.10051 25.8995C0.633164 20.4322 0.633164 11.5678 6.10051 6.10051ZM23.7491 6.83666C19.0344 2.84053 11.9629 3.06655 7.51472 7.51472C3.06655 11.9629 2.84053 19.0344 6.83666 23.7491L23.7491 6.83666ZM8.25087 25.1633C12.9656 29.1595 20.0371 28.9335 24.4853 24.4853C28.9335 20.0371 29.1595 12.9656 25.1633 8.25087L8.25087 25.1633Z"
                fill="#757575" />
            </svg>
          </div>
          <p class="text-light-450 font-regular text-12 line-clamp-2 leading-[14px]">Product not found</p>
        </div>
        <div v-if="!isSalePage" class="flex">
          <GButtonV2
            type="tertiary"
            size="medium"
            button-width="full"
            icon-before="cursor-target"
            icon-view-box="0 0 16 16"
            icon-size="16"
            @click="openModal">
            Pick products
          </GButtonV2>
        </div>
        <div v-else class="mt-4 flex items-center">
          <span class="text-text-dark-300 text-12 font-regular leading-5"
            >You can change it in Funnel's
            <span class="text-primary-250 cursor-pointer" @click="openSaleFunnel">Trigger tab</span></span
          >
        </div>
      </div>
      <MultiProductModal
        :value="cloneList"
        :list="listSortable"
        :type="'Product'"
        :open="productListVisibility"
        :is-loading="isLoading"
        :is-post-purchase="isPostPurchase"
        :max="max"
        :sync-percent="syncPercent"
        :page-type="pageType"
        :max-allow-selected="maxAllowSelected"
        :input-search-component="inputSearchComponent"
        :warning-message="warningMessage"
        :variant-selected="variantSelected"
        @show-more="emit('showMore', 'fetch')"
        @refresh="emit('controlRefresh')"
        @close="closeModal"
        @set-value-selected="setValueSelected"
        @set-pre-variant-selected="setPreVariantSelected"
        @on-search="onSearch" />
    </div>
  </div>
</template>
